var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { style: _vm.style },
        [
          _c(
            "el-button",
            {
              attrs: { type: "text", icon: "el-icon-circle-plus-outline" },
              on: { click: _vm.btnAdd },
            },
            [_vm._v("添加 ")]
          ),
        ],
        1
      ),
      _vm._l(_vm.tasks, function (itemRow, itemIndex) {
        return _c(
          "div",
          { key: itemIndex, staticClass: "rule-row" },
          [
            _c("span", [_vm._v("每完成一次：")]),
            _c(
              "el-select",
              {
                attrs: {
                  disabled: _vm.disabled,
                  placeholder: "请选择",
                  clearable: "",
                },
                on: {
                  change: function ($event) {
                    return _vm.emitChange(itemRow.integralTaskType, "task")
                  },
                },
                model: {
                  value: _vm.tasks[itemIndex].integralTaskType,
                  callback: function ($$v) {
                    _vm.$set(_vm.tasks[itemIndex], "integralTaskType", $$v)
                  },
                  expression: "tasks[itemIndex].integralTaskType",
                },
              },
              _vm._l(_vm.libData.calcList, function (item) {
                return _c("el-option", {
                  key: item.dictCode,
                  attrs: { value: item.dictCode, label: item.dictValue },
                })
              }),
              1
            ),
            _c("span", { staticClass: "margin-left-6" }, [_vm._v("获得：")]),
            _c("el-input-number", {
              staticStyle: {},
              attrs: {
                placeholder: "请输入积分数量",
                min: 0,
                clearable: "",
                disabled: _vm.disabled,
              },
              on: { change: _vm.emitChange },
              model: {
                value: itemRow.integralTaskReward,
                callback: function ($$v) {
                  _vm.$set(itemRow, "integralTaskReward", $$v)
                },
                expression: "itemRow.integralTaskReward",
              },
            }),
            _c("span", [_vm._v("积分")]),
            _c(
              "el-button",
              {
                staticClass: "margin-left-6 fontsize-16",
                style: _vm.style,
                attrs: { icon: "el-icon-remove-outline", type: "text" },
                on: {
                  click: function ($event) {
                    return _vm.btnRemove(itemIndex)
                  },
                },
              },
              [_vm._v("删除 ")]
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }