var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        modalConfig: {
          type: "Modal",
          visible: _vm.dialog.visible,
          title: "选择组织",
          width: "80%",
        },
        destroyOnClose: "",
      },
      on: { cancel: _vm.btnClose, ok: _vm.btnConfirm, onClose: _vm.btnClose },
    },
    [
      _c(
        "vxe-table",
        {
          attrs: {
            border: "",
            "tree-config": { children: "children" },
            data: _vm.tableData,
          },
        },
        [
          _c("vxe-table-column", {
            attrs: { width: "100px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("vxe-checkbox", {
                      attrs: { disabled: row.disabled },
                      on: {
                        change: function ($event) {
                          return _vm.tableCheckChange(row)
                        },
                      },
                      model: {
                        value: row.checked,
                        callback: function ($$v) {
                          _vm.$set(row, "checked", $$v)
                        },
                        expression: "row.checked",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: { "tree-node": "", field: "orgName", title: "组织名称" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small", icon: "el-icon-check" },
              on: { click: _vm.btnConfirm },
            },
            [_vm._v("提交 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-close" },
              on: { click: _vm.btnClose },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }