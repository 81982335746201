<template>
    <modal  :modalConfig="{type:'Modal',visible:dialog.visible,title:'选择组织',width:'80%'}"
            @cancel="btnClose" @ok="btnConfirm" destroyOnClose @onClose="btnClose">
      <vxe-table border
                :tree-config="{ children: 'children' }"
                :data="tableData">
          <vxe-table-column width="100px">
            <template v-slot="{row}">
              <vxe-checkbox :disabled="row.disabled" v-model="row.checked" @change="tableCheckChange(row)"></vxe-checkbox>
            </template>
          </vxe-table-column>
          <vxe-table-column tree-node field="orgName" title="组织名称"></vxe-table-column>
      </vxe-table>
      <div class="dialog-footer">
        <el-button type="primary" @click="btnConfirm" size="small" icon="el-icon-check">提交 </el-button>
        <el-button type="danger" @click="btnClose" size="small" icon="el-icon-close">关闭</el-button>
      </div>
    </modal>
</template>

<script>
import modal from '../../../../../../components/modal';
import request from '../../../../../../utils/request';

/** @desc 获取选中的列表 */
const getTreeDataCheckList = (param) => {
  const checkList = [];
  function recure(arr) {
    // eslint-disable-next-line array-callback-return
    arr.map((item) => {
      if (item.checked) checkList.push(item);
      if (item.children && Array.isArray(item.children) && item.children.length > 0) {
        recure(item.children);
      }
    });
  }
  recure(param);
  return checkList;
};

/** @desc 设置数据选中 */
const setTreeDataChecked = (treeData, checkList) => {
  const recure = (arr) => {
    arr.forEach((row) => {
      const item = row;
      const findCheck = checkList.find((v) => item.orgCode === v.code);
      item.checked = !!findCheck;
      if (item.children && Array.isArray(item.children) && item.children.length > 0) {
        recure(item.children);
      }
    });
  };
  recure(treeData);
};

export default {
  components: { modal },
  props: {
    visible: Boolean,
    selectList: Array,
  },
  computed: {
    composeValue() {
      const { visible, selectList } = this;
      return { visible, selectList };
    },
  },
  watch: {
    composeValue: {
      handler(curVal) {
        if (curVal.visible) {
          this.getInitList();
        }
        this.dialog.visible = curVal.visible;
      },
    },
  },
  data() {
    return {
      dialog: {
        visible: false,
      },
      tableData: [],
    };
  },
  methods: {
    /** @desc 提交事件 */
    btnConfirm() {
      const checkList = getTreeDataCheckList(this.tableData);
      this.$emit('confirm', checkList);
      this.btnClose();
    },
    /** @desc 关闭事件 */
    btnClose() {
      this.treeData = [];
      this.$emit('update:visible', false);
    },
    /** ---------------------------------以下是表格事件----------------------------------------*/
    /** @desc 获取初始化列表 */
    async getInitList() {
      try {
        const treeResult = await request.post('/sfa/sfaIntegralRule/selectOrgList');
        const treeData = treeResult ? treeResult.result : [];
        setTreeDataChecked(treeData, this.selectList);
        this.tableData = treeData;
      } catch (error) { console.log('执行错误====', error); }
    },
    /** @desc 表格选中改变事件 */
    tableCheckChange(row) {
      function recure(arr) {
        arr.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          if (!item.disabled) {
            // eslint-disable-next-line no-param-reassign
            item.checked = row.checked;
          }
          if (item.children && Array.isArray(item.children) && item.children.length > 0) {
            recure(item.children);
          }
        });
      }
      if (row.children && Array.isArray(row.children) && row.children.length > 0) {
        const cloneChildren = JSON.parse(JSON.stringify(row)).children;
        recure(cloneChildren);
        this.$set(row, 'children', cloneChildren);
      }
    },
  },
};
</script>
