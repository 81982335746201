<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';

import BasicInfo from './components/basic_info.vue';
import ScopeOfApplication from './components/scope_of_application.vue';
import CalcRule from './components/calc_rule.vue';

formCreate.component('sfaPointManageBasicInfo', BasicInfo);
formCreate.component('sfaPointManageScopeOfApplication', ScopeOfApplication);
formCreate.component('sfaPointManageCalcRule', CalcRule);

export default {
  extends: Form,
  data() {
    return {
      buttons: {
        submit: true,
      },
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  methods: {
    getDetail() {
      if (!this.formConfig.row.id) return;
      request.get('/sfa/sfaIntegralRule/queryRule', { id: this.formConfig.row.id }).then((res) => {
        if (!res.success || !res.result) return;

        this.setValue({
          basicInfo: {
            integralRuleName: res.result.integralRuleName,
            remarks: res.result.remarks,
            startTime: res.result.startTime,
            endTime: res.result.endTime,
          },
          scopeApply: {
            orgs: res.result.orgs ? res.result.orgs : [],
            posLevel: res.result.pos ? res.result.pos : [],
          },
          calcRule: res.result.tasks ? res.result.tasks : [],
        });
      });
    },
    /** @override 覆盖提交事件 */
    submit() {
      // todo 1.判断是否填完了
      const formData = this.getFormData();
      if (!formData) return;

      // todo 2.重写赋值数据
      const requestParam = {
        ...formData.basicInfo,
        tasks: formData.calcRule ? [...formData.calcRule] : [],
        orgs: [],
        posLevel: [],
      };
      if (formData.scopeApply && formData.scopeApply.orgs) {
        requestParam.orgs = formData.scopeApply.orgs;
      }
      if (formData.scopeApply && formData.scopeApply.posLevel) {
        requestParam.posLevel = formData.scopeApply.posLevel;
      }

      // todo 3.区分是编辑还是新增
      let requestUrl = '/sfa/sfaIntegralRule/saveRule';
      if (this.formConfig.row && this.formConfig.row.id) {
        requestUrl = '/sfa/sfaIntegralRule/updateRule';
        requestParam.id = this.formConfig.row.id;
      }
      request.post(requestUrl, requestParam).then((res) => {
        this.$message.success('操作成功!');
        this.$emit('onClose');
        this.$emit('onGetList');
      });
    },
  },
  /** @desc 重新赋值rule,反正感觉怪怪的有问题 */
  async created() {
    await this.getFormRule('sfaCenter_pointManage_pointRule_form');
    this.rule.forEach((item) => {
      if (item.field === 'basicInfo') {
        Object.assign(item, {}, { props: { disabled: this.formConfig.disabled } });
      }
      if (item.field === 'scopeApply') {
        Object.assign(item, {}, { props: { disabled: this.formConfig.disabled } });
      }
      if (item.field === 'calcRule') {
        Object.assign(item, {}, { props: { disabled: this.formConfig.disabled } });
      }
    });
    this.buttons.submit = !this.formConfig.disabled;

    if (this.formConfig.row && this.formConfig.row.id) {
      this.getDetail();
    }
  },
};
</script>
