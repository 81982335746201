<template>
  <div>
    <div :style="style">
      <el-button type="text" icon="el-icon-circle-plus-outline"
                 @click="btnAdd">添加
      </el-button>
      <!-- <el-button
        icon="el-icon-refresh"
        @click="handleRefreshRule">刷新规则
      </el-button> -->
    </div>
    <div class="rule-row" v-for="(itemRow,itemIndex) in tasks" :key="itemIndex">
      <span>每完成一次：</span>
      <el-select v-model="tasks[itemIndex].integralTaskType" @change="emitChange(itemRow.integralTaskType, 'task')"
                 :disabled="disabled" placeholder="请选择" clearable>
        <el-option v-for="item in libData.calcList"
                   :key="item.dictCode"
                   :value="item.dictCode"
                   :label="item.dictValue">
        </el-option>
      </el-select>
      <span class="margin-left-6">获得：</span>
      <el-input-number v-model="itemRow.integralTaskReward"
                       @change="emitChange"
                       placeholder="请输入积分数量"
                       :min="0" clearable style="min-width-178"
                       :disabled="disabled">
      </el-input-number>
      <span>积分</span>
      <el-button icon="el-icon-remove-outline"
                 class="margin-left-6 fontsize-16"
                 :style="style"
                 type="text"
                 @click="btnRemove(itemIndex)">删除
      </el-button>
    </div>
  </div>
</template>

<script>
import request from '../../../../../../utils/request';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: Array,
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(curVal) {
        if (curVal && Array.isArray(curVal)) {
          this.tasks = [...curVal];
        }
      },
    },
  },
  data() {
    return {
      libData: {
        calcList: [], // integralTaskReward 积分数值 integralTaskType 积分类型
      },
      ruleList: [], // 积分规则 全部
      tasks: [],
    };
  },
  computed: {
    style() {
      return { display: this.disabled ? 'none' : 'inline-block' };
    },
  },
  methods: {
    /** @desc 获取依赖数据 */
    getLibData() {
      const requestParam = {
        dictTypeCode: 'integral_task',
        selectedCode: null,
      };
      request.post('/mdm/mdmdictdata/list', requestParam).then((res) => {
        if (!res.success) return;
        this.libData.calcList = res.result;
        this.ruleList = res.result;
      });
    },
    /** @desc 按钮添加 */
    btnAdd() {
      const item = { integralTaskReward: '', integralTaskType: '' };
      this.tasks = this.tasks.concat([item]);
      this.emitChange();
    },
    /** @desc 按钮删除 */
    btnRemove(index) {
      this.tasks.splice(index, 1);
      this.emitChange();
    },
    emitChange(val, type = null) {
      if (type === 'task') {
        this.libData.calcList = this.libData.calcList.filter((item) => item.dictCode !== val);
      }
      this.$emit('input', this.tasks);
    },
    /** @desc 刷新规则 */
    handleRefreshRule() {
      this.libData.calcList = this.ruleList;
    },
  },
  created() {
    this.getLibData();
  },
};
</script>
<style lang="less" scoped>
.rule-row {
  display: flex;
  margin-top: 6px;
  align-items: center;
  margin-bottom: 6px;
}
.margin-left-6{
  margin-left: 6px;
}
.fontsize-16{
  font-size: 16px;
}
.min-width-178{
  min-width: 178px;
}
</style>
