<script>
import TablePage from '../../../../../components/table_page';

import Form from './form.vue';

export default {
  name: 'table-component',
  extends: TablePage,
  components: { Form },
  data() {
    return {
      requestUrl: '/sfa/sfaIntegralRule/listRule',
      formConfig: {},
    };
  },
  methods: {
    modalClick({ val, row }) {
      console.log('table点击=========按钮', val);
      if (val.code === 'add') return this.btnAdd();
      if (val.code === 'edit') return this.btnEdit(row);
      if (val.code === 'view') return this.btnCheck(row);
    },
    /** @desc 新增 */
    btnAdd() {
      this.formName = 'Form';
      this.formConfig.row = {};
      this.formConfig.disabled = false;
      this.modalConfig.title = '新增';
      this.openFull();
    },
    /** @desc 编辑 */
    btnEdit(row) {
      this.formName = 'Form';
      this.formConfig.row = { ...row };
      this.formConfig.disabled = false;
      this.modalConfig.title = '编辑';
      this.openFull();
    },
    btnCheck(row) {
      this.formName = 'Form';
      this.formConfig.row = { ...row };
      this.formConfig.disabled = true;
      this.modalConfig.title = '查看';
      this.openFull();
    },
  },
  created() {
    this.getConfigList('sfaCenter_pointManage_pointRule');
  },

};
</script>
