<template>
  <el-form label-width="80px">
    <el-row>
      <el-col :span="10">
        <el-form-item label="规则名称">
          <el-input v-model="integralRuleName"
                    @change="emitChange"
                    :readonly="disabled">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="10">
        <el-form-item label="有效期">
          <el-date-picker v-model="dateRange"
                          :readonly="disabled"
                          range-separator="至"
                          format="yyyy-MM-dd hh:mm:ss"
                          value-format="yyyy-MM-dd hh:mm:ss"
                          type="datetimerange"
                          @change="emitChange">
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="20">
        <el-form-item label="备注">
          <el-input v-model="remarks"
                    @change="emitChange"
                    type="textarea"
                    :autosize="{minRows:5,maxRows:8}"
                    :readonly="disabled">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {
          integralRuleName: '',
          remarks: '',
          startTime: '',
          endTime: '',
        };
      },
    },
  },
  watch: {
    value: {
      handler(curVal) {
        if (curVal && isObject(curVal)) {
          this.integralRuleName = curVal.integralRuleName;
          this.remarks = curVal.remarks;
          if (curVal.startTime && curVal.endTime) {
            this.dateRange = [curVal.startTime, curVal.endTime];
          }
        }
      },
    },
  },
  data() {
    return {
      integralRuleName: '',
      remarks: '',
      dateRange: '',
    };
  },
  methods: {
    emitChange() {
      console.log('时间框改变事件========', this.dateRange);
      this.$emit('input', {
        integralRuleName: this.integralRuleName,
        remarks: this.remarks,
        startTime: this.dateRange && this.dateRange.length > 0 ? this.dateRange[0] : '',
        endTime: this.dateRange && this.dateRange.length > 0 ? this.dateRange[1] : '',
      });
    },
  },
};
</script>
