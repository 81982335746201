var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { attrs: { "label-width": "80px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "规则名称" } },
                [
                  _c("el-input", {
                    attrs: { readonly: _vm.disabled },
                    on: { change: _vm.emitChange },
                    model: {
                      value: _vm.integralRuleName,
                      callback: function ($$v) {
                        _vm.integralRuleName = $$v
                      },
                      expression: "integralRuleName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "有效期" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      readonly: _vm.disabled,
                      "range-separator": "至",
                      format: "yyyy-MM-dd hh:mm:ss",
                      "value-format": "yyyy-MM-dd hh:mm:ss",
                      type: "datetimerange",
                    },
                    on: { change: _vm.emitChange },
                    model: {
                      value: _vm.dateRange,
                      callback: function ($$v) {
                        _vm.dateRange = $$v
                      },
                      expression: "dateRange",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 20 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 5, maxRows: 8 },
                      readonly: _vm.disabled,
                    },
                    on: { change: _vm.emitChange },
                    model: {
                      value: _vm.remarks,
                      callback: function ($$v) {
                        _vm.remarks = $$v
                      },
                      expression: "remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }