var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "margin-bottom-10" },
        [
          _c(
            "el-row",
            { staticClass: "margin-bottom-6" },
            [
              _c("span", [_vm._v("适用组织：")]),
              _c(
                "el-button",
                {
                  style: _vm.style,
                  attrs: { type: "text", icon: "el-icon-circle-plus-outline" },
                  on: { click: _vm.btnOrgAdd },
                },
                [_vm._v("添加 ")]
              ),
            ],
            1
          ),
          _c(
            "vxe-table",
            { attrs: { data: _vm.orgs } },
            [
              _c("vxe-table-column", {
                attrs: { title: "序号", width: "70px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var rowIndex = ref.rowIndex
                      return [_vm._v(_vm._s(rowIndex + 1))]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: { title: "组织编码", field: "code" },
              }),
              _c("vxe-table-column", {
                attrs: { title: "组织名称", field: "name" },
              }),
              !_vm.disabled
                ? _c("vxe-table-column", {
                    attrs: { title: "操作", width: "100px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var rowIndex = ref.rowIndex
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.btnOrgRemove(rowIndex)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3430927411
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "margin-bottom-10" },
        [
          _c(
            "el-row",
            { staticClass: "margin-bottom-6" },
            [
              _c("span", [_vm._v("适用职位级别：")]),
              _c(
                "el-button",
                {
                  style: _vm.style,
                  attrs: { type: "text", icon: "el-icon-circle-plus-outline" },
                  on: { click: _vm.btnPosAdd },
                },
                [_vm._v("添加 ")]
              ),
            ],
            1
          ),
          _c(
            "vxe-table",
            { attrs: { data: _vm.posLevel } },
            [
              _c("vxe-table-column", {
                attrs: { title: "序号", width: "70px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var rowIndex = ref.rowIndex
                      return [_vm._v(_vm._s(rowIndex + 1))]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: { title: "职位级别编码", field: "code" },
              }),
              _c("vxe-table-column", {
                attrs: { title: "职位级别名称", field: "name" },
              }),
              !_vm.disabled
                ? _c("vxe-table-column", {
                    attrs: { title: "操作", width: "100px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var rowIndex = ref.rowIndex
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.btnPosRemove(rowIndex)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2011350277
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("OrgModal", {
        attrs: { selectList: _vm.orgs, visible: _vm.modalConfig.orgVisible },
        on: {
          "update:visible": function ($event) {
            return _vm.$set(_vm.modalConfig, "orgVisible", $event)
          },
          confirm: _vm.orgModalConfirm,
        },
      }),
      _c("PosModal", {
        attrs: {
          selectList: _vm.posLevel,
          visible: _vm.modalConfig.posVisible,
        },
        on: {
          "update:visible": function ($event) {
            return _vm.$set(_vm.modalConfig, "posVisible", $event)
          },
          confirm: _vm.posModalConfirm,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }