var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { modalConfig: _vm.modalConfig },
      on: { onClose: _vm.modalClose },
    },
    [
      _c("el-autocomplete", {
        ref: "autoComplete",
        staticStyle: { width: "50%" },
        attrs: {
          "fetch-suggestions": _vm.getAutoCompleteList,
          placeholder: "请输入职位级别名称",
          clearable: "",
        },
        on: { select: _vm.autocompleteChange, clear: _vm.clear },
        model: {
          value: _vm.autocompleteState,
          callback: function ($$v) {
            _vm.autocompleteState = $$v
          },
          expression: "autocompleteState",
        },
      }),
      _c(
        "vxe-table",
        { staticClass: "margin-top-8", attrs: { data: _vm.tableData } },
        [
          _c("vxe-table-column", {
            attrs: { title: "组织编码", field: "code" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "组织名称", field: "name" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var rowIndex = ref.rowIndex
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.btnRemove(rowIndex)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small", icon: "el-icon-check" },
              on: { click: _vm.modalConfirm },
            },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-close" },
              on: { click: _vm.modalClose },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }