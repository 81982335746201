<template>
  <div>
    <div class="margin-bottom-10">
      <el-row class="margin-bottom-6">
        <span>适用组织：</span>
        <el-button type="text" icon="el-icon-circle-plus-outline"
                   :style="style" @click="btnOrgAdd">添加
        </el-button>
      </el-row>
      <vxe-table :data="orgs">
        <vxe-table-column title="序号" width="70px">
          <template v-slot="{rowIndex}">{{rowIndex+1}}</template>
        </vxe-table-column>
        <vxe-table-column title="组织编码" field="code"></vxe-table-column>
        <vxe-table-column title="组织名称" field="name"></vxe-table-column>
        <vxe-table-column title="操作" width="100px" v-if="!disabled">
          <template v-slot="{rowIndex}">
            <el-button type="text" @click="btnOrgRemove(rowIndex)">删除</el-button>
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <div class="margin-bottom-10">
      <el-row class="margin-bottom-6">
        <span>适用职位级别：</span>
        <el-button type="text" icon="el-icon-circle-plus-outline"
                   :style="style" @click="btnPosAdd">添加
        </el-button>
      </el-row>
      <vxe-table :data="posLevel">
        <vxe-table-column title="序号" width="70px">
          <template v-slot="{rowIndex}">{{rowIndex+1}}</template>
        </vxe-table-column>
        <vxe-table-column title="职位级别编码" field="code"></vxe-table-column>
        <vxe-table-column title="职位级别名称" field="name"></vxe-table-column>
        <vxe-table-column title="操作" width="100px" v-if="!disabled">
          <template v-slot="{rowIndex}">
            <el-button type="text" @click="btnPosRemove(rowIndex)">删除</el-button>
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <OrgModal :selectList="orgs" :visible.sync="modalConfig.orgVisible" @confirm="orgModalConfirm"></OrgModal>
    <PosModal :selectList="posLevel" :visible.sync="modalConfig.posVisible" @confirm="posModalConfirm"></PosModal>
  </div>
</template>

<script>
import PosModal from './pos_modal.vue';
import OrgModal from './org_modal.vue';

export default {
  components: { PosModal, OrgModal },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {
          posLevel: [],
          orgs: [],
        };
      },
    },
  },
  watch: {
    value: {
      handler(curVal) {
        if (curVal) {
          if (curVal.orgs && Array.isArray(curVal.orgs)) this.orgs = curVal.orgs;
          if (curVal.posLevel && Array.isArray(curVal.posLevel)) this.posLevel = curVal.posLevel;
        }
      },
    },
  },
  data() {
    return {
      modalConfig: {
        posVisible: false,
        orgVisible: false,
      },
      posLevel: [], // 职位列表 code,name
      orgs: [], // 岗位列表 code,name
    };
  },
  computed: {
    style() {
      return { display: this.disabled ? 'none' : 'inline-block' };
    },
  },
  methods: {
    /** @desc 组织新增按钮 */
    btnOrgAdd() {
      this.modalConfig.orgVisible = true;
    },
    /** @desc 组织删除按钮 */
    btnOrgRemove(index) {
      this.orgs.splice(index, 1);
      this.emitChange();
    },
    /** @desc 组织确认按钮 */
    orgModalConfirm(param) {
      console.log('组织确认的数据===========', param);
      this.orgs = param.map((item) => ({ ...item, code: item.orgCode, name: item.orgName }));
      this.emitChange();
    },
    /** @desc 职位新增按钮 */
    btnPosAdd() {
      this.modalConfig.posVisible = true;
    },
    /** @desc 职位删除按钮 */
    btnPosRemove(index) {
      this.posLevel.splice(index, 1);
      this.emitChange();
    },
    /** @desc 职位确认按钮 */
    posModalConfirm(param) {
      console.log('职位执行确认事件=======', param);
      this.posLevel = [...param];
      this.emitChange();
    },
    emitChange() {
      this.$emit('input', {
        posLevel: this.posLevel,
        orgs: this.orgs,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.margin-bottom-6{
  margin-bottom: 6px;
}
.margin-bottom-10{
  margin-bottom: 10px;
}
</style>
