<template>
  <Modal :modalConfig="modalConfig" @onClose="modalClose">
    <el-autocomplete v-model="autocompleteState"
                     :fetch-suggestions="getAutoCompleteList"
                     placeholder="请输入职位级别名称"
                     @select="autocompleteChange"
                     style="width:50%"
                     clearable
                     @clear="clear"
                     ref="autoComplete">
    </el-autocomplete>
    <vxe-table :data="tableData" class="margin-top-8">
      <vxe-table-column title="组织编码" field="code"></vxe-table-column>
      <vxe-table-column title="组织名称" field="name"></vxe-table-column>
      <vxe-table-column title="操作">
        <template v-slot="{ rowIndex }">
          <el-button type="text" @click="btnRemove(rowIndex)">删除</el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <div class="dialog-footer">
      <el-button type="primary" @click="modalConfirm" size="small" icon="el-icon-check" >提交</el-button>
      <el-button type="danger" @click="modalClose" size="small" icon="el-icon-close" >关闭</el-button>
    </div>
  </Modal>
</template>

<script>
import Modal from '../../../../../../components/modal/components/modal.vue';
import request from '../../../../../../utils/request';
import * as utils from '../../../../utils/index';

export default {
  components: { Modal },
  props: {
    visible: Boolean,
    selectList: Array,
  },
  data() {
    return {
      tableData: [],
      modalConfig: {
        type: 'Modal',
        width: '80%',
        visible: false,
        title: '适用职位级别',
        showFooter: false,
      },
      autocompleteState: '',
    };
  },
  watch: {
    composeValue: {
      handler(curVal) {
        console.log('pos_modal监听==========', curVal);
        if (curVal.visible && Array.isArray(curVal.selectList)) {
          this.tableData = [...curVal.selectList];
        }
        this.modalConfig.visible = curVal.visible;
      },
    },
  },
  computed: {
    /** @desc 组合监听 */
    composeValue() {
      const { visible, selectList } = this;
      return { visible, selectList };
    },
  },
  methods: {
    /** @desc 获取远程数据 */
    getAutoCompleteList(query, callback) {
      // todo 1.没有输入值不执行
      // todo 2.有输入值继续执行
      request.post('/sfa/sfaIntegralRule/selectPosList', { positionLevelName: query }).then((res) => {
        if (!res.success || !res.result) return;
        const searchData = res.result.map((v) => ({ value: v.positionLevelName, name: v.positionLevelName, code: v.positionLevelCode }));
        callback(searchData);
      });
    },
    /** @desc 点击清除失去input框焦点 */
    clear() {
      this.$refs.autoComplete.$children[0].blur();
    },
    /** @desc 输入框改变事件 */
    autocompleteChange(item) {
      this.tableData = utils.duplicate(this.tableData.concat([item]), 'code');
    },
    btnRemove(index) {
      this.tableData.splice(index, 1);
    },
    /** @desc 弹窗确认时间 */
    modalConfirm() {
      this.$emit('confirm', this.tableData);
      this.modalClose();
    },
    /** @desc 弹窗关闭事件 */
    modalClose() {
      this.$emit('update:visible', false);
      this.tableData = [];
      this.autocompleteState = '';
    },
  },
};
</script>

<style lang="less" scoped>
.margin-top-8{
  margin-top: 8px;
}
</style>
